<template>
  <div>
    <!-- {{ NavbarItemsFromLogin }} -->
    <!-- {{ filteredItems }} -->
    <v-navigation-drawer
      :permanent="navigation.ifPermanent"
      ref="drawer"
      app
      :dark="darkSwitch"
      :width="navigation.width"
      v-model="navigation.shown"
    >
      <v-layout column align-center>
        <v-flex>
          <v-avatar @click="goTo('/cu/dashboard')" size="150" tile class="ma-3">
            <img :src="require('@/assets/nu_logos/nu_logo_r.png')" />
          </v-avatar>
        </v-flex>
      </v-layout>
      <v-card>
        <v-card-title class="title font-weight-light">
          <v-layout align-center justify-center row fill-height text-capitalize
            >Welcome to College User Panel</v-layout
          >
        </v-card-title>
      </v-card>
      <v-list>
        <router-link
          to="/cu/dashboard"
          class="nav-link navigation__list"
          aria-selected="false"
        >
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>home</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Home</v-list-tile-title>
          </v-list-tile>
        </router-link>

        <router-link
          to="/cu/profile"
          class="nav-link navigation__list"
          aria-selected="false"
        >
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>account_circle</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Profile</v-list-tile-title>
          </v-list-tile>
        </router-link>

        <router-link
          to="/cu/ff/probable-list"
          class="nav-link navigation__list"
          aria-selected="false"
        >
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>done_all</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Confirm Data Entry</v-list-tile-title>
          </v-list-tile>
        </router-link>

        <router-link
          to="/cu/ff/probable-data"
          class="nav-link navigation__list"
          aria-selected="false"
        >
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>view_list</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Probable Candidate List</v-list-tile-title>
          </v-list-tile>
        </router-link>

        <!-- <v-list-tile @click="goTo('/cu/ff/pending-student-data')">
          <v-list-tile-action>
            <v-icon>menu_book</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>Pending Data (Student)</v-list-tile-title>
        </v-list-tile>-->
        <router-link
          to="/cu/ff/confirm-student-data"
          class="nav-link navigation__list"
          aria-selected="false"
        >
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>list_alt</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Applied Candidate List</v-list-tile-title>
          </v-list-tile>
        </router-link>

        <router-link
          to="/cu/ff/confirm-data"
          class="nav-link navigation__list"
          aria-selected="false"
        >
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>filter_list</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Confirmed Candidate List</v-list-tile-title>
          </v-list-tile>
        </router-link>

        <router-link
          to="/cu/ff/admit-card"
          class="nav-link navigation__list"
          aria-selected="false"
        >
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>cloud_done</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Admit Card</v-list-tile-title>
          </v-list-tile>
        </router-link>

        <v-list-group>
          <template v-slot:activator>
            <v-list-tile>
              <v-icon>science</v-icon>
              <v-list-tile-content>
                <v-list-tile-title style="margin-left: 30px;"
                  >Practical</v-list-tile-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <router-link
            to="/cu/practical/mark-list"
            class="nav-link navigation__list"
            aria-selected="false"
          >
            <v-list-tile>
              <v-list-tile-action>
                <v-icon>input</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Practical Exam Marks</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </router-link>

          <router-link
            to="/cu/practical/candidate-list"
            class="nav-link navigation__list"
            aria-selected="false"
          >
            <v-list-tile>
              <v-list-tile-action>
                <v-icon>list</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Practical Candidate List</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </router-link>

        </v-list-group>

        

        <v-list-group>
          <template v-slot:activator>
            <v-list-tile>
              <v-icon>meeting_room</v-icon>
              <v-list-tile-content>
                <v-list-tile-title style="margin-left: 30px;"
                  >Viva</v-list-tile-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <router-link
            to="/cu/viva/mark-list"
            class="nav-link navigation__list"
            aria-selected="false"
          >
            <v-list-tile>
              <v-list-tile-action>
                <v-icon>input</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Viva Exam Marks</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </router-link>

          <router-link
            to="/cu/viva/candidate-list"
            class="nav-link navigation__list"
            aria-selected="false"
          >
            <v-list-tile>
              <v-list-tile-action>
                <v-icon>list</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Viva Candidate List</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </router-link>
        </v-list-group>




        <!-- <router-link
          to="/cu/ff/fee_statement"
          class="nav-link navigation__list"
          aria-selected="false"
          >
          <v-list-tile>
          <v-list-tile-action>
            <v-icon>money</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>Fee Summary</v-list-tile-title>
        </v-list-tile>
        </router-link> -->

        <v-list-tile @click="goTo('/cu/ff/payment_slip')">
          <v-list-tile-action>
            <v-icon>receipt_long</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>Fee Summary</v-list-tile-title>
        </v-list-tile>

        <!-- <router-link
          to="/cu/result-correction-application"
          class="nav-link navigation__list"
          aria-selected="false"
          >
          <v-list-tile>
          <v-list-tile-action>
            <v-icon>check_circle</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>Result Correction</v-list-tile-title>
        </v-list-tile>
        </router-link> -->

        <v-list-group>
          <template v-slot:activator>
            <v-list-tile>
              <v-icon>help_outline</v-icon>
              <v-list-tile-content>
                <v-list-tile-title style="margin-left: 30px;"
                  >Help Desk</v-list-tile-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <router-link
            to="/cu/notices"
            class="nav-link navigation__list"
            aria-selected="false"
          >
            <v-list-tile>
              <v-list-tile-action>
                <v-icon>find_in_page</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Notice</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </router-link>

          <router-link
            to="/cu/faqs"
            class="nav-link navigation__list"
            aria-selected="false"
          >
            <v-list-tile>
              <v-list-tile-action>
                <v-icon>quiz</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Faq</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </router-link>

          <router-link
            to="/cu/general-instructions"
            class="nav-link navigation__list"
            aria-selected="false"
          >
            <v-list-tile>
              <v-list-tile-action>
                <v-icon>integration_instructions</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>General Instruction</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </router-link>

          <router-link
            to="/cu/special-instructions"
            class="nav-link navigation__list"
            aria-selected="false"
          >
            <v-list-tile>
              <v-list-tile-action>
                <v-icon>explore</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Special Instruction</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </router-link>
        </v-list-group>

        <router-link
          to="/cu/support"
          class="nav-link navigation__list"
          aria-selected="false"
        >
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>support</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Support</v-list-tile-title>
          </v-list-tile>
        </router-link>

        <router-link
          to="/cu/change-password"
          class="nav-link navigation__list"
          aria-selected="false"
        >
          <v-list-tile>
            <v-list-tile-action>
              <v-icon>password</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Change Password</v-list-tile-title>
          </v-list-tile>
        </router-link>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  props: ["navigation"],
  computed: {
    userName() {
      return JSON.parse(localStorage.getItem("student")).name;
    },
    //snacker status getter
    direction() {
      return this.navigation.shown === false ? "Open" : "Closed";
    },
    icon_dark() {
      return this.darkSwitch === false ? "" : "white";
    },
    title_dark() {
      return this.darkSwitch === false ? "black" : "white";
    },
  },
  data: () => ({
    // search: "",
    // searchItem: [],
    // langs: ["ja", "en"],
    // ifPermanent: true,
    // NavbarItems: navbarItems,
    darkSwitch: false,
    drawer: true,
  }),
  methods: {
    goHome() {
      this.$router.push("/college/dashboard");
    },
    goTo(val) {
      //   alert(val);
      this.$router.push({ path: val }).catch((err) => {});
    },
  },
};
</script>
<style type="text/css">
.nav-link {
  text-decoration: none;
}
.nav-link.router-link-exact-active div {
  background-color: #d3d6e8;
}
</style>
